export enum UserStatusType {
  active = 'Active',
  canceled = 'Cancelled',
  DISABLED = 'Disabled',
  INVALID_SUBSCRIPTION = 'Invalid subscription',
  incomplete = 'Incomplete',
  incomplete_expired = 'Expired',
  past_due = 'Past Due',
  unpaid = 'Unpaid',
  trialing = 'Trial',
}
