import { FetchMoreOptions, useMutation, useQuery } from '@apollo/client';
import Layout from '@layouts';
import { getQueryName, NOOP } from '@lib';
import clsx from 'clsx';
import * as React from 'react';
import { FC, ReactNode, useState } from 'react';

import {
  ChangeUserStatusDocument,
  GetCategoriesDocument,
  UsersForManagementPageDocument,
  UsersForManagementPageQuery,
  UsersForManagementPageQueryVariables,
  UserStatus,
} from '@redsleeve/oilynx-domain';

import Button from '@components/Button';
import DotsMenu from '@components/DotsMenu';
import UserStatusBadge from '@components/user/UserStatusBadge';
import { UserStatusType } from '@components/user/domain';

import {
  COUNTRY_UNDEFINED,
  countryValues,
  mapCountryToSelectValue,
} from '@data/countries';

import { getErrorMessages } from '@lib/errors';
import { useOrderedCategories } from '@lib/hooks/useOrderedCategories';

import './user-management.css';

enum SortType {
  nameASC = 'name',
  nameDESC = '-name',
  companyASC = 'companyName',
  companyDESC = '-companyName',
  interestASC = 'interest',
  interestDESC = '-interest',
  paymentASC = '',
  paymentDESC = '',
}

const ITEM_PER_PAGE = 50;

const UserManagementPage: FC = () => {
  const categoriesQuery = useQuery(GetCategoriesDocument);
  const orderedCategories = useOrderedCategories(categoriesQuery.data);
  const [sortType, setSortType] = useState<SortType>(SortType.nameASC);
  const [usersLeft, setUsersLeft] = useState(0);
  const usersQuery = useQuery(UsersForManagementPageDocument, {
    variables: {
      pagination: {
        first: ITEM_PER_PAGE,
        offset: 0,
        sort: sortType,
      },
    },
    ssr: false,
  });

  const [changeUserStatus] = useMutation(ChangeUserStatusDocument, {
    refetchQueries: [getQueryName(UsersForManagementPageDocument)],
  });

  const userManagementHandler = async (id: string, status: any) => {
    try {
      await changeUserStatus({
        variables: { id, status },
      });
    } catch (ex) {
      const errorMessages = getErrorMessages(ex);
      if (errorMessages) alert(errorMessages.join('\n'));
      else console.error('[Admin User Management page]', ex);
    }
  };
  const scrollDown = () => {
    let updateQuery: FetchMoreOptions<
      UsersForManagementPageQuery,
      UsersForManagementPageQueryVariables
    >['updateQuery'] = (prev, { fetchMoreResult }) => {
      if (!fetchMoreResult) return prev;
      const usersManagementLeft = {
        usersForManagementPage: [
          ...prev.usersForManagementPage,
          ...fetchMoreResult.usersForManagementPage,
        ],
      };
      setUsersLeft(usersManagementLeft.usersForManagementPage.length);
      return usersManagementLeft;
    };
    usersQuery
      .fetchMore({
        variables: {
          pagination: {
            first: ITEM_PER_PAGE,
            offset: usersQuery.data?.usersForManagementPage?.length,
            sort: sortType,
          },
        },
        updateQuery,
      })
      .then(NOOP);
  };

  const getRows = () => {
    let rows: ReactNode[] = [];
    if (usersQuery.data)
      usersQuery.data.usersForManagementPage.map((user) => {
        const flag =
          countryValues.find(
            (country) => country.value.isoCode === user.country
          ) ?? mapCountryToSelectValue(true, COUNTRY_UNDEFINED);
        const status =
          user.status === UserStatus.Disabled
            ? UserStatusType.DISABLED
            : UserStatusType.active;
        // : UserStatusType[
        //     (Object.keys(UserStatusType).find(
        //       (item) => item === user.subscriptionDetails?.status
        //     ) || 'INVALID_SUBSCRIPTION') as keyof typeof UserStatusType
        //   ];
        rows.push(
          <tr key={user.id} className={'TableRowData hover:bg-background-avg'}>
            <td
              className={
                'border-b font-body Tooltip-wrapper  text-left text-sm text-white  truncate border-gray-700  '
              }
            >
              <span className="flex items-center">
                <div
                  className={clsx(
                    'rounded-full  h-2.5 w-2.5 absolute ',
                    // 'bg-status-enabled',
                    status === UserStatusType.active ||
                      status === UserStatusType.trialing
                      ? 'bg-status-enabled'
                      : 'bg-status-reject'
                  )}
                />

                <span className="ml-5">{user.name}</span>
                {user.name.length > 18 && (
                  <span className="invisible w-48 ml-40 mt-5  rounded absolute z-10 bg-background-dark text-interaction-transparent text-center">
                    {user.name}
                  </span>
                )}
              </span>
            </td>
            <td
              className={
                'border-b font-body   text-left text-sm text-white truncate border-gray-700  '
              }
            >
              <UserStatusBadge status={status} />
            </td>
            <td
              className={
                'Tooltip-wrapper font-body text-left text-sm text-white border-gray-700 truncate'
              }
            >
              {user.roles.join(', ')}
              <span className="invisible w-48 mt-5  rounded absolute z-10 bg-background-dark text-interaction-transparent text-center">
                {user.roles.join(', ')}
              </span>
            </td>
            <td
              className={
                ' font-body text-left text-sm text-white truncate border-gray-700  '
              }
            >
              {user.roleDescription?.length
                ? user.roleDescription
                : user.interests.map((interest, index) => {
                    return (
                      <span key={Math.floor(Math.random() * 1000) + 1}>
                        {(index ? ', ' : '') +
                          orderedCategories.find((item) => item.id === interest)
                            ?.name}
                      </span>
                    );
                  })}
            </td>
            <td
              className={
                'font-body text-left text-sm text-white truncate border-gray-700 align-center  '
              }
            >
              <span className="flex  items-center ">
                <img
                  className={'w-5 h-5 mb-0'}
                  alt={`flag of ${flag.value.name}`}
                  src={flag.value.flag}
                />
                <span className="pl-3">{user.phoneNumber}</span>
              </span>
            </td>
            <td
              className={
                'Tooltip-wrapper font-body text-left text-sm text-white border-gray-700 truncate'
              }
            >
              {user.email}
              <span className="invisible w-48 mt-5  rounded absolute z-10 bg-background-dark text-interaction-transparent text-center">
                {user.email}
              </span>
            </td>
            <td
              className={
                'font-body text-left text-sm text-white truncate border-gray-700 '
              }
            >
              {user.companyName}, {user.companyAddress}
              {user.companyName.length + user.companyAddress.length > 18 && (
                <span className="invisible w-48 mt-5 rounded absolute z-10 bg-background-dark text-interaction-transparent text-center">
                  {user.companyName}, {user.companyAddress}
                </span>
              )}
            </td>
            <td
              className={'border-b border-t border-gray-700 truncate pt-0 pb-0'}
            >
              <div className={'w-full h-auto'}>
                <DotsMenu
                  key={user.id}
                  changeStatus={() =>
                    userManagementHandler(
                      user.id,
                      user.status === 'ENABLED' ? 'DISABLED' : 'ENABLED'
                    )
                  }
                  status={user.status === 'ENABLED' ? 'Disable' : 'Enable'}
                  link={`https://dashboard.stripe.com/customers/${user.stripeCustomerId}`}
                />
              </div>
            </td>
          </tr>
        );
      });
    return rows;
  };

  return (
    <Layout url="/admin/user-management/" title="User Management" disableIndex>
      <p className="text-center text-white font-emp opacity-100 capitalize tracking-normal font-bold text-3xl">
        User Management
      </p>
      <table className="w-full table-fixed whitespace-nowrap UserManagement-wrapper">
        <tbody>
          <tr>
            <th
              className={
                'TableFullNameCol text-sm font-emp  text-gray-500 border-b  border-gray-700'
              }
            >
              <div className={'flex'}>
                <div
                  className={'text-left  fontFamily '}
                  onClick={() => {
                    sortType === SortType.nameASC
                      ? setSortType(SortType.nameDESC)
                      : setSortType(SortType.nameASC);
                  }}
                >
                  Full Name
                </div>
                <div>
                  <div className={'flex-col text-xs leading-3 pl-1 '}>
                    <div>
                      <button onClick={() => setSortType(SortType.nameASC)}>
                        ▲
                      </button>
                    </div>
                    <div>
                      <button onClick={() => setSortType(SortType.nameDESC)}>
                        ▼
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </th>

            <th
              className={
                'TableFullNameCol text-sm font-emp  text-gray-500 border-b  border-gray-700'
              }
            >
              <div className={'flex'}>
                <div
                  className={'text-left  fontFamily '}
                  onClick={() => {
                    sortType === SortType.nameASC
                      ? setSortType(SortType.nameDESC)
                      : setSortType(SortType.nameASC);
                  }}
                >
                  Payment Status
                </div>
                <div>
                  <div className={'flex-col text-xs leading-3 pl-1'}>
                    <div>
                      <button onClick={() => setSortType(SortType.nameASC)}>
                        ▲
                      </button>
                    </div>
                    <div>
                      <button onClick={() => setSortType(SortType.nameDESC)}>
                        ▼
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </th>
            <th
              className={
                'text-sm font-emp text-gray-500 border-b   border-gray-700'
              }
            >
              <div className={'flex'}>
                <div className={'text-left fontFamily '}>Type</div>
              </div>
            </th>

            <th
              className={
                'text-sm font-emp text-gray-500 border-b   border-gray-700'
              }
            >
              <div className={'flex'}>
                <div className={'text-left fontFamily '}>Products / Role</div>
                <div>
                  <div className={'flex-col leading-3 text-xs pl-1'}>
                    <div>
                      <button onClick={() => setSortType(SortType.interestASC)}>
                        ▲
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={() => setSortType(SortType.interestDESC)}
                      >
                        ▼
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </th>
            <th
              className={
                'text-sm font-emp text-gray-500 border-b leading-3   border-gray-700'
              }
            >
              Phone Number
            </th>
            <th
              className={
                'text-sm font-emp text-gray-500 border-b leading-3   border-gray-700'
              }
            >
              Email
            </th>
            <th
              className={
                'text-sm font-emp text-gray-500 border-b  border-gray-700'
              }
            >
              <div className={'flex'}>
                <div
                  className={'text-left fontFamily '}
                  onClick={() => {
                    sortType === SortType.companyASC
                      ? setSortType(SortType.companyDESC)
                      : setSortType(SortType.companyASC);
                  }}
                >
                  Company Details
                </div>
                <div>
                  <div className={'flex-col leading-3 text-xs pl-1'}>
                    <div>
                      <button onClick={() => setSortType(SortType.companyASC)}>
                        ▲
                      </button>
                    </div>
                    <div>
                      <button onClick={() => setSortType(SortType.companyDESC)}>
                        ▼
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </th>
            <th className={' border-b w-10 border-gray-700'} />
          </tr>
          {getRows()}
        </tbody>
      </table>
      <div className="text-center">
        {usersLeft === usersQuery.data?.usersForManagementPage?.length ? (
          <p className="text-white">No more users</p>
        ) : (
          <Button
            type="submit"
            name="submit"
            className="hover:bg-interaction-enabled hover:text-white"
            onClick={scrollDown}
          >
            Load more
          </Button>
        )}
      </div>
    </Layout>
  );
};

export default UserManagementPage;
