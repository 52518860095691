import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

import { UserStatusType } from '@components/user/domain';

type UserStatusBadgeProps = {
  status: UserStatusType;
  className?: string;
};

const UserStatusBadge: FC<UserStatusBadgeProps> = ({ className, status }) => {
  return (
    <div
      className={clsx(
        className,
        `border w-10/12 max-w-10/12 min-w-max text-center rounded p-0.5 px-2`,
        status === UserStatusType.active
          ? 'text-status-active border-status-active bg-status-active bg-opacity-10'
          : status === UserStatusType.unpaid
          ? 'text-status-unpaid border-status-unpaid bg-status-unpaid bg-opacity-10'
          : status === UserStatusType.incomplete_expired
          ? 'text-status-unpaid border-status-unpaid bg-status-unpaid bg-opacity-10'
          : status === UserStatusType.past_due
          ? 'text-status-unpaid border-status-unpaid bg-status-unpaid bg-opacity-10'
          : status === UserStatusType.incomplete
          ? 'text-status-cancelled border-status-cancelled bg-status-cancelled bg-opacity-10'
          : status === UserStatusType.canceled
          ? 'text-status-cancelled border-status-cancelled bg-status-cancelled bg-opacity-10'
          : status === UserStatusType.DISABLED
          ? 'text-status-cancelled  border-status-cancelled bg-status-cancelled bg-opacity-10'
          : status === UserStatusType.INVALID_SUBSCRIPTION
          ? 'text-status-cancelled border-status-cancelled bg-status-cancelled bg-opacity-10'
          : 'border-status bg-status-trial text-status bg-opacity-10'
      )}
    >
      {status}
    </div>
  );
};

export default UserStatusBadge;
