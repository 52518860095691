import { useModal } from '@lib';
import clsx from 'clsx';
import { Link } from 'gatsby';
import * as React from 'react';
import { FC, useState } from 'react';

interface MenuProps {
  status: string;
  link: string;
  changeStatus: () => void;
}

const DotsMenu: FC<MenuProps> = ({ status, link, changeStatus }) => {
  const [dotsMenu, setDotsMenu] = useState<boolean>(false);
  const menuControl = useModal();
  return (
    <>
      {menuControl.isOpen && (
        <div
          className="fixed inset-0"
          aria-hidden="true"
          onClick={menuControl.close}
        >
          <div
            className="absolute inset-0 bg-almostTransparent"
            onClick={() => setDotsMenu(false)}
          />
        </div>
      )}
      <div onClick={menuControl.open}>
        <div
          className={'Three-dots'}
          onClick={() => {
            setDotsMenu(!dotsMenu);
          }}
        ></div>
        <div
          className={clsx(
            'w-36 bg-interaction-statusDots absolute flex-col -ml-36 text-interaction-transparent text-left rounded-sm items-center font-body text-sm',
            dotsMenu ? 'block' : 'hidden'
          )}
        >
          <div className="  pl-2 h-6 flex items-center">
            <Link
              className="no-underline text-interaction-transparent w-full "
              to={link}
              target={'_blank'}
            >
              Invoice Monitoring
            </Link>
          </div>
          <hr className={'m-0 bg-interaction-border'} />
          <div className="pl-2 h-6 flex items-center">
            <button className="pb-0 w-full text-left" onClick={changeStatus}>
              {status}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DotsMenu;
